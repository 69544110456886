import React, { Component, useState, Fragment, useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import drake from "./img/drake.jpg";
import logo1 from "./img/logo-m.png";
import logo2 from "./img/logo-text.png";
import logo from "./img/logo.png";
import desliza from "./img/desliza-leer-mas.png";
import "typeface-exo-2";

import "reset-css";

const Spacer = ({ height = 10, width }) => (
  <div
    style={{
      height: `${height}px`,
      width: width ? `${width}px` : "100%",
      display: "inline-block"
    }}
  />
);

const Story = ({ text, image, showSlide, onClose }) => {
  const [output, setOutput] = useState(null);
  const storyRef = useRef(null);

  useEffect(
    () => {
      if (!storyRef.current) {
        return;
      }

      html2canvas(storyRef.current, { logging: false}).then(
        canvas => {
          const imgData = canvas.toDataURL("image/png");
          setOutput(imgData);
        },
        [storyRef.current]
      );
    },
    [storyRef.current]
  );

  return (
    <div
      onClick={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "black"
      }}>
      {output ? (
        <img src={output} onClick={onClose} />
      ) : (
        <div
          ref={storyRef}
          style={{
            height: "177.77vw" /* 16:9 Aspect Ratio */,
            maxHeight: "100%",
            width: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            background: "black"
          }}>
          <div
            style={{
              backgroundImage: `url(${image})`,

              backgroundSize: "cover",
              backgroundPosition: "center",
              flex: 1
            }}
          />
          <header
            style={{
              display: "flex"
            }}>
            <div
              style={{
                flex: 1,
                backgroundColor: "#DA291C",
                padding: "10px"
              }}>
              <img src={logo1} style={{ maxWidth: "100%" }} />
            </div>
            <div
              style={{
                flex: 6,
                padding: "10px"
              }}>
              <img src={logo2} style={{ maxWidth: "100%" }} />

              <h1
                style={{
                  textTransform: "uppercase",
                  fontFamily: `"Exo 2", Times, serif`,
                  fontSize: "6vw",
                  color: "white"
                }}>
                {text}
              </h1>
              {showSlide && (
                <Fragment>
                  <Spacer height={10} />
                  <img src={desliza} style={{ maxWidth: "100%" }} />
                  <Spacer height={25} />
                </Fragment>
              )}
            </div>
          </header>
        </div>
      )}
    </div>
  );
};

const App = () => {
  const [text, setText] = useState(null);
  const [image, setImage] = useState(null);
  const [isStoryVisible, setStoryVisible] = useState(false);
  const [showSlide, setShowSlide] = useState(false);

  const handleImageUpload = evt => {
    if (!evt.target) {
      setImage(null);
      return;
    }

    const file = evt.target.files[0];
    setImage(URL.createObjectURL(file));
  };

  const handlePreview = () => {
    setStoryVisible(true);
  };

  return isStoryVisible ? (
    <Story
      text={text}
      image={image}
      showSlide={showSlide}
      onClose={() => setStoryVisible(false)}
    />
  ) : (
    <div
      style={{
        backgroundColor: "black",
        color: "white",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: `"Exo 2", Times, serif`
      }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}>
        <img src={logo} style={{ width: "300px" }} />
        <Spacer height={40} />
        <input
          type="text"
          placeholder="Texto (opcional)"
          style={{
            padding: "10px",
            fontSize: "1em",
            width: "250px",
            fontFamily: `"Exo 2", Times, serif`
          }}
          onChange={evt => setText(evt.target.value)}
        />
        <Spacer height={20} />
        <input
          type="file"
          onChange={handleImageUpload}
          style={{ visibility: "hidden", position: "absolute" }}
          id="file"
        />
        <label
          htmlFor="file"
          style={{
            fontSize: "1.1em",
            backgroundColor: "#DA291C",
            color: "white",
            padding: "10px",
            cursor: "pointer"
          }}>
          {image ? (
            <img src={image} style={{ height: "50px" }} />
          ) : (
            <span>📸 &nbsp; SUBIR IMAGEN</span>
          )}
        </label>

        <Spacer height={15} />
        <div style={{ fontSize: "1.2em" }}>
          <input
            type="checkbox"
            id="showSlide"
            onChange={evt => {
              setShowSlide(evt.target.checked);
            }}
          />
          <Spacer width={7} />
          <label htmlFor="showSlide">Mostrar "desliza para ver"</label>
        </div>
        <Spacer height={40} />
        <button
          onClick={handlePreview}
          style={{
            fontSize: "3em",
            padding: "0 10px",
            backgroundColor: "#DA291C",
            color: "white",
            border: 0
          }}>
          🚀
        </button>
      </div>
    </div>
  );
};

export default App;
